import React, { useEffect, useState, useRef } from 'react';
import Uppy from '@uppy/core';
import Dashboard from '@uppy/dashboard';
import Tus from '@uppy/tus';
import { debounce } from 'lodash';
import ImageEditor from '@uppy/image-editor';
import DropTarget from '@uppy/drop-target';
import Transloadit from '@uppy/transloadit';
import Webcam from '@uppy/webcam';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/image-editor/dist/style.css';
import '@uppy/screen-capture/dist/style.css';
import './styles.css';
import logo from './assets/images/avatar-maker-icon.png';
import ResizeObserver from 'resize-observer-polyfill';
import img2 from './assets/images/dashboard2.png';
import { SetCookie, GetCookie, RemoveCookie } from './utils/cookies';
import Swal from 'sweetalert2';

window.ResizeObserver = ResizeObserver;
var isLegalAge = false;


const Avatar = () => {
  const UPLOADER = 'transloadit';
  const TUS_ENDPOINT = 'https://tusd.tusdemo.net/files/';
  let selectedAspectRatioRef = React.useRef('');
  let uppyDashboardRef = React.useRef(null);
  let positivepromptRef = React.useRef(null);
  let [base64data, setBase64Data] = useState(null);
  const [selectedAspectRatio, setSelectedAspectRatio] = useState('');
  let [outputWidth, setOutputWidth] = useState('768');
  let [outputHeight, setOutputHeight] = useState('768');
  const startApiUrl = process.env.REACT_APP_START_API_URL ? process.env.REACT_APP_START_API_URL : "https://start.ai-pro.org/api";
  const sdUrl = process.env.REACT_APP_SD_AIPRO ? process.env.REACT_APP_SD_AIPRO : 'https://imagine.ai-pro.org';
  const sdApiUrl = process.env.REACT_APP_SD_API_AIPRO ? process.env.REACT_APP_SD_API_AIPRO : 'https://imagine.ai-pro.org';
  const apiUrl = sdApiUrl + '/e/sdapi/v1/txt2img';
  const upscaleUrl = sdUrl + '/e/sdapi/v1/extra-single-image';
  const [allowedToGenerate, setAllowedToGenerate] = useState(false);
  const [allowedToType, setAllowedToType] = useState(true);
  const [hasFileUploaded, setHasFileUploaded] = useState(false);
  const resultEl = React.useRef(null);
  // Import the polyfill
  const randomfolderNameRef = useRef(null);

  useEffect(() => {
    if (randomfolderNameRef.current === null) {
      randomfolderNameRef.current = generateRandomString();
    }
  }, []);


  const setGenBtnViaPrompt = (event) => {
    console.log(hasFileUploaded);
    if (event.target.value.trim() !== '' && hasFileUploaded) {
      setAllowedToGenerate(true);
    } else {
      setAllowedToGenerate(false);
    }
  }
  const scrollToTop = () => {
    resultEl.current.scrollIntoView({ behavior: 'smooth' });
  }
  const [dashboardUrl, setDashboardUrl] = useState('');
  const [showDashboardLink, setShowDashboardLink] = useState(true);

  useEffect(() => {
    document.title = "Avatar Maker | AI Pro";
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', 'Experience the Art of Illusion using AI and be able to create your digital self.');
    }
  });

  useEffect(() => {
    let reader = new FileReader();

    if (!GetCookie('back_link')) {
      setShowDashboardLink(false);
    } else {
      setDashboardUrl(GetCookie('back_link'));
    }

    uppyDashboardRef.current = new Uppy({
      debug: true,
      restrictions: {
        maxFileSize: null,
        maxNumberOfFiles: 1,
        minNumberOfFiles: 1,
        allowedFileTypes: ['.jpg', '.jpeg', '.png', '.gif'],
      },
    })
      .use(Dashboard, {
        inline: true,
        hideUploadButton: true,
        target: '#upload_area',
        showProgressDetails: false,
        autoOpenFileEditor: true,
        accentColor: '#ff0000',
        width: '100%',
        height: '60vh',
        disableStatusBar: true,
        hideCancelButton: true,
        locale: {
          strings: {
            dropPasteFiles: 'Drop image here or %{browseFiles}',
            browseFiles: 'browse file',
            done: 'Upload New',
          },
        },
        proudlyDisplayPoweredByUppy: false,
      })
      .use(Webcam, {
        target: Dashboard,
        modes: ['picture'],
        mirror: true,
        locale: {
          strings: {
            allowAccessTitle: '',
            allowAccessDescription: '',
          },
        },
        showRecordingLength: false,
        showVideoSourceDropdown: false,
      })
      .use(ImageEditor, {
        target: Dashboard,
        actions: {
          granularRotate: false,
        },
      })
      .use(DropTarget, {
        target: document.body,
      });

    switch (UPLOADER) {
      case 'tus':
        uppyDashboardRef.current.use(Tus, { endpoint: TUS_ENDPOINT, limit: 6 });
        break;
      case 'transloadit':
        uppyDashboardRef.current.use(Transloadit, {
          assemblyOptions: {
            params: {
              auth: { key: 'cb2a20a7c29d439280d4a8e4408ae5ff' },
              template_id: 'd84b2acb378c4fc9ab39f4968e8a63c4',
            },
          },
        });
        break;
      default:
        break;
    }
    uppyDashboardRef.current.on('file-editor:start', (file) => {
      var selector = '#uppy-DashboardContent-panel--editor > div.uppy-DashboardContent-bar > button.uppy-DashboardContent-back';
      new Promise(resolve => {
        if (document.querySelector(selector)) {
          return resolve(document.querySelector(selector));
        }
        const observer = new MutationObserver(mutations => {
          if (document.querySelector(selector)) {
            resolve(document.querySelector(selector));
            observer.disconnect();
          }
        });
        observer.observe(document.body, {
          childList: true,
          subtree: true
        });
      }).then(() => {
        document.querySelector(selector)?.addEventListener('click', () => {
          uppyDashboardRef.current.removeFile(file.id)
        });
      });
    });

    // Event listener for file edited
    uppyDashboardRef.current.on('file-editor:complete', (updatedFile) => {
      setHasFileUploaded(true);
      if (positivepromptRef.current.value) {
        setAllowedToGenerate(true);
      }
      reader.onloadend = () => {
        setBase64Data(reader.result);
      };
      reader.readAsDataURL(updatedFile.data);
    });

    const aspectRatioButtons = document.querySelectorAll('.aspect-ratio-btn');

    // Event listener for file removed
    uppyDashboardRef.current.on('file-removed', (file, reason) => {
      setHasFileUploaded(false);
      const imgBtns = document.getElementById('imgBtns');
      positivepromptRef.current.value = "";
      if (imgBtns) {
        imgBtns.remove();
      }

      aspectRatioButtons.forEach((btn) => {
        btn.classList.remove('active');
        if (btn.textContent === 'Square (1:1)') {
          btn.classList.add('active');
        }
      });


      console.log('image removed!');
      setAllowedToGenerate(false);
    });

    // Handle aspect ratio button click
    aspectRatioButtons.forEach((button) => {
      button.addEventListener('click', () => {
        const width = button.getAttribute('data-width');
        const height = button.getAttribute('data-height');
        const isActive = button.classList.contains('active');

        if (!isActive) {
          aspectRatioButtons.forEach((btn) => {
            btn.classList.remove('active');
          });
          button.classList.add('active');
          setOutputWidth(width);
          setOutputHeight(height);
          setSelectedAspectRatio(`${width}x${height}`);
          // console.log(`Selected aspect ratio: ${selectedAspectRatioRef.current}`);
        } else {
          selectedAspectRatioRef.current = '';
        }
      });
    });

    // Handle upload completion
    uppyDashboardRef.current.on('complete', (result) => {
      if (result.failed.length === 0) {
        console.log('Upload successful');
      } else {
        console.warn('Upload failed');
      }
    });
    const debouncedHandleResize = debounce(() => {
      console.log('Resize occurred');
    }, 200);

    const resizeObserver = new ResizeObserver((entries) => {
      // Call the debounced resize handler
      debouncedHandleResize();
    });

    if (resultEl.current) { // Assuming resultEl is a ref to the container element
      resizeObserver.observe(resultEl.current);
    }

    return () => {
      // Cleanup
      uppyDashboardRef.current.close();
      uppyDashboardRef.current = null;
      resizeObserver.disconnect();
    };
  }, []);

  function createUpgradePopup() {
    const overlay = document.createElement('div');
    overlay.className = 'popup-overlay';
    document.body.appendChild(overlay);

    const upgradePopup = document.createElement('div');
    upgradePopup.className = 'popup container mx-auto flex flex-col justify-between';
    upgradePopup.innerHTML = `
<h1 class="popup-content mb-4 text-4xl font-bold" style="font-size: 2rem;">Get Full Access to <br> Avatar Maker</h1>
      <p class="text-md popup_desc" style="font-weight: 300;">Upgrade to the most powerful AI image generator ever, created just for you. Get full access to all advanced features by clicking Continue below.</p>
      <button type="button" class="btn bold mx-auto mt-6 w-1/3 px-12 py-4 text-center text-1xl text-white bg-blue-500 border-blue-500 hover:bg-blue-700 hover:border-blue-800 hover:text-white rounded" style="font-size: .8rem;">Continue</button>
    `;
    document.body.appendChild(upgradePopup);

    const continueButton = upgradePopup.querySelector('.btn');
    continueButton.addEventListener('click', () => {
      SetCookie('avatarmaker', `${randomfolderNameRef.current}`, { domain: '.ai-pro.org', path: '/' });
      if (!window.AIPRO_USER || !window.AIPRO_USER.email) window.location.href = process.env.REACT_APP_START_URL + "/register";
      else window.location.href = process.env.REACT_APP_START_URL + "/pricing";

      if (window.self !== window.top) {
        if (!window.AIPRO_USER || !window.AIPRO_USER.email)
          window.top.location.href = process.env.REACT_APP_START_URL + "/register";
        else
          window.top.location.href = process.env.REACT_APP_START_URL + "/pricing";
      } else {
        if (!window.AIPRO_USER || !window.AIPRO_USER.email)
          window.location.href = process.env.REACT_APP_START_URL + "/register";
        else
          window.location.href = process.env.REACT_APP_START_URL + "/pricing";
      }
    });

    overlay.addEventListener('click', () => {
      upgradePopup.remove();
      overlay.remove();
      RemoveCookie('avatarmaker', { domain: '.ai-pro.org', path: '/' });
    });
  }

  function generateRandomString() {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for (let i = 0; i < 15; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const handleDownloadHighResolution = (imageData, index, ver = 0) => {

    const payload = {
      resize_mode: 0,
      show_extras_results: true,
      gfpgan_visibility: 0,
      codeformer_visibility: 0,
      codeformer_weight: 0,
      upscaling_resize: 1.5,
      upscaling_resize_w: imageData.width,
      upscaling_resize_h: imageData.height,
      upscaling_crop: true,
      upscaler_1: "4x-UltraSharp",
      upscaler_2: "None",
      extras_upscaler_2_visibility: 0,
      upscale_first: false,
      image: imageData.imgBase64
    };

    fetch(upscaleUrl, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        return response.json();
      })
      .then((output) => {

        uploadToS3(output.image, randomfolderNameRef.current, index);

        const link = document.querySelectorAll('.high-resolution-btn[version="'+ ver +'"]');
        link[0].classList.remove("opacity-50", "cursor-not-allowed", "pointer-events-none");
        const svgContainer = document.querySelector('.svg-container');
        link[0].style.cursor = "pointer";
        if (svgContainer) {
          svgContainer.remove();
        }

        if ((window.AIPRO_USER.subscription_type) && ((window.AIPRO_USER.subscription_type.includes('PRO')) || (window.AIPRO_USER.subscription_type.includes('PROMAX')) || (window.AIPRO_USER.subscription_type.includes('ENTERPRISE')))) {
          link[0].href = `data:image/png;base64, ${output.image}`;
          link[0].download = `image_${index}.png`;
        }
      })
      .catch(error => {
        console.log('Error:', error)
      });
  };

  const uploadToS3 = async (base64Data, folderName, index) => {
    const imageBase64 = base64Data;
    const payload = {
      folder: folderName,
      base: imageBase64
    }
    fetch(`${startApiUrl}/upload-avatar`, {
      method: 'POST',
      body: new URLSearchParams(payload).toString(),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  const showAgeConfirmation = (fn = ()=>{}) => {
    Swal.fire({
      title: "Confirmation Required",
      html: "Please confirm that you are 18 years old or above.",
      showCancelButton: true,
      confirmButtonText: "Confirm",
      confirmButtonColor: "#16a34a"
    }).then((result) => {
      if (result.isConfirmed) {
        isLegalAge = true;
        fn();
      }
    });
  }

  // Handle image generation button click
  const handleGenerateButtonClick = async () => {
    setAllowedToGenerate(false);
    const apiGetUsageUrl = process.env.REACT_APP_HOST_API + "/e/get-usage/" || "https://api.ai-pro.org/e/get-usage/";
    const apiSetUsageUrl = process.env.REACT_APP_HOST_API + "/e/set-usage/" || "https://api.ai-pro.org/e/set-usage/";

    const is_limited = window.is_limited;
    const showModal = await is_limited(apiGetUsageUrl, apiSetUsageUrl);
    if (showModal) {
      return;
    }
    const TPLogicRun = window.TPLogicRun;
    if (typeof TPLogicRun === 'function') {
      TPLogicRun();
      // console.log("TPLogicRun is a function");
    } else {
      console.error('TPLogicRun is not a function');
    }

    const positivePromptInput = document.getElementById('positive-prompt').value;
    const negativePromptInput = document.getElementById('negative-prompt').value;
    const resultContainer = document.getElementById('result-container');
    setAllowedToType(false);
    const dashboard = uppyDashboardRef.current.getPlugin('Dashboard');
    dashboard.setOptions({ disabled: true });

    scrollToTop();
    resultContainer.innerHTML = '<span class="loader"></span>';
    const args = [
      base64data,
      true, //1 Enable ReActor
      '0', //2 Comma separated face number(s) from swap-source image
      '0', //3 Comma separated face number(s) for target image (result)
      '/var/www/stable-diffusion-webui/models/insightface/inswapper_128.onnx', //4 model path
      'CodeFormer', //4 Restore Face: None; CodeFormer; GFPGAN
      1, //5 Restore visibility value
      false, //7 Restore face -> Upscale
      'None', //8 Upscaler (type 'None' if doesn't need), see full list here: http://127.0.0.1:7860/sdapi/v1/script-info -> reactor -> sec.8
      1.5, //9 Upscaler scale value
      1, //10 Upscaler visibility (if scale = 1)
      false, //11 Swap in source image
      true, //12 Swap in generated image
      0, //13 Console Log Level (0 - min, 1 - med or 2 - max)
      0, //14 Gender Detection (Source) (0 - No, 1 - Female Only, 2 - Male Only)
      0, //15 Gender Detection (Target) (0 - No, 1 - Female Only, 2 - Male Only)
      false, //16 Save the original image(s) made before swapping
      0.5, //17 CodeFormer Weight (0 = maximum effect, 1 = minimum effect), 0.5 - by default
      false, //18 Source Image Hash Check, True - by default
      false, //19 Target Image Hash Check, False - by default
      "CUDA", //20 CPU or CUDA (if you have it), CPU - by default
      true, //21 Face Mask Correction
      0, //22 Select Source, 0 - Image, 1 - Face Model, 2 - Source Folder
      "RealitiesEdgeXL_4.safetensors", //23 Filename of the face model (from "models/reactor/faces"), e.g. elena.safetensors, don't forger to set //22 to 1
      "/var/www/stable-diffusion-webui/faces", //24 The path to the folder containing source faces images, don't forger to set //22 to 2
      'None', //25 skip it for API
      true, //26 Randomly select an image from the path
      true, //27 Force Upscale even if no face found
      0.6, //28 Face Detection Threshold
      1, //29 Maximum number of faces to detect (0 is unlimited)
    ];
    const payload = {
      enable_hr: false,
      denoising_strength: 0,
      firstphase_width: 0,
      firstphase_height: 0,
      hr_scale: 2,
      hr_upscaler: "",
      hr_second_pass_steps: 0,
      hr_resize_x: 0,
      hr_resize_y: 0,
      prompt: positivePromptInput,
      styles: [""],
      seed: -1,
      subseed: -1,
      loading: 'Loading...',
      subseed_strength: 0,
      seed_resize_from_h: -1,
      seed_resize_from_w: -1,
      sampler_name: "",
      batch_size: 1,
      n_iter: 1,
      steps: 10,
      cfg_scale: 7,
      width: selectedAspectRatio === "" ? '768' : outputWidth,
      height: selectedAspectRatio === "" ? '768' : outputHeight,
      restore_faces: false,
      tiling: false,
      do_not_save_samples: false,
      do_not_save_grid: false,
      negative_prompt: negativePromptInput + 'BadDream, (UnrealisticDream:1.3), duplicate',
      eta: 0,
      s_min_uncond: 0,
      s_churn: 0,
      s_tmax: 0,
      s_tmin: 0,
      s_noise: 1,
      override_settings: {},
      override_settings_restore_afterwards: true,
      script_args: [],
      sampler_index: "DPM++ 2M",
      script_name: "",
      send_images: true,
      save_images: false,
      alwayson_scripts: {
        reactor:{
          args
        }
      }
    };

    fetch(apiUrl, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        return response.json();
      })
      .then((output) => {
        var htmlOutput = '';
        setAllowedToGenerate(true);
        let ver = new Date().getTime();
        let view_img_btn = '<button class="view-img"><i class="fa fa-eye" aria-hidden="true"></i> View Image</button>';
        for (var i = 0; i < output.images.length; i++) {
          htmlOutput += '<div class="flex flex-wrap" id="imgBtns">';
          htmlOutput += '<div class="overflow-hidden img-container '+ (output.blur[0] ? "blur-img" : "") +'"><img class="img-fluid w-full" src="data:image/png;base64, ' + output.images[i] + '" />'+view_img_btn+'</div>';
          htmlOutput += '<div class="w-full text-center pt-4">Download Image in:';
          htmlOutput += '</div>';
          htmlOutput += '<div class="flex w-full sm:w-1/2 sm:pr-1 pt-4">';
          htmlOutput += '<a href="data:image/png;base64, ' + output.images[i] + '" download="image_' + i + '.png" class="font-extrabold btn btn-primary w-full py-2 mr-1 rounded border border-[#16A34A] text-black hover:text-white bg-white hover:bg-[#16A34A] low-resolution-btn">Low Resolution</a>';
          htmlOutput += '</div>';
          htmlOutput += '<div class="flex w-full sm:w-1/2 sm:pl-1 pt-4">';
          htmlOutput += `<a version='${ver}' class="font-extrabold btn btn-primary w-full py-2 mr-1 rounded border border-[#16A34A] text-black hover:text-white bg-white hover:bg-[#16A34A] high-resolution-btn opacity-50 cursor-not-allowed pointer-events-none" data-index="${i}"><svg aria-hidden="true" role="status" class="svg-container inline w-4 h-4 mr-3 text-green-200 animate-spin dark:text-green-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2"/>
            </svg>High Resolution `
            if (!window.AIPRO_USER || typeof window.AIPRO_USER.subscription_type === 'undefined' || !window.AIPRO_USER.subscription_type || !(window.AIPRO_USER.subscription_type.includes('PRO') || !(window.AIPRO_USER.subscription_type.includes('PROMAX')) || !(window.AIPRO_USER.subscription_type.includes('ENTERPRISE')))) {
            htmlOutput += `<span class="badge badge-primary" style="display: inline-block;"> Pro</span>`
          }

          htmlOutput += `</a>`;
          htmlOutput += '</div>';
          htmlOutput += '</div>';
        }

        resultContainer.innerHTML = htmlOutput;
        dashboard.setOptions({ disabled: false });
        setAllowedToType(true);
        if(output.blur[0]) {
          const viewImage = document.querySelectorAll('.img-container');
          viewImage.forEach((button) => {
            button.addEventListener('click', () => {
              let fn = () => {
                if(button.classList.contains("blur-img")) {
                  button.classList.remove("blur-img");
                } else {
                  button.classList.add("blur-img");
                }
              }
              if(isLegalAge) {
                fn();
                return;
              }
              showAgeConfirmation(fn);
            })
          });
        }

        const highResolutionButtons = document.querySelectorAll('.high-resolution-btn');
        const lowResolutionButtons = document.querySelectorAll('.low-resolution-btn');
        highResolutionButtons.forEach((button) => {
          const index = button.getAttribute('data-index');
          const imageData = {
            imgBase64: output.images[index],
            height: output.parameters.height,
            width: output.parameters.width
          }

          handleDownloadHighResolution(imageData, index, ver);
          button.addEventListener('click', (e) => {
            const fn = () => {
              if (!window.AIPRO_USER || typeof window.AIPRO_USER.subscription_type === 'undefined' || !window.AIPRO_USER.subscription_type || !(window.AIPRO_USER.subscription_type.includes('PRO') || !(window.AIPRO_USER.subscription_type.includes('PROMAX')) || !(window.AIPRO_USER.subscription_type.includes('ENTERPRISE')))) {
                SetCookie("app", "pro", { domain: '.ai-pro.org', path: '/' });
                createUpgradePopup();
              }
              SetCookie('remakemedloption', 'highResolution', { domain: '.ai-pro.org', path: '/' });
            }
            if(output.blur[0] && !isLegalAge) {
              e.preventDefault();
              showAgeConfirmation(()=> {
                button.click();
              });
              return;
            }
            fn();
          });
        });
        lowResolutionButtons.forEach((btn) => {
          btn.addEventListener('click', (e) => {
            const fn = () => {
              SetCookie('remakemedloption', 'lowResolution', { domain: '.ai-pro.org', path: '/' });
            };

            if(output.blur[0] && !isLegalAge) {
              e.preventDefault();
              showAgeConfirmation(()=> {
                btn.click();
              });
              return;
            }
            fn();
          })
        })

      })
      .catch(error => {
        console.log(error)
      });
  };

  return (
    <>
      <div className="flex max-w-7xl mx-auto flex-col items-center justify-center py-2 min-h-screen">
        <header className="headeravatar flex flex-col xs:flex-row justify-between items-center w-full mt-3 border-b pb-5 sm:px-4 px-2 border-gray-500 gap-2 header-center" aria-label='Header'>
          {showDashboardLink && (
            <a href={dashboardUrl} className="back-dashboard" aria-label='Back to Dashboard' >
              <img
                alt="back button"
                src={img2}
                className="sm:w-5 sm:h-5 w-5 h-5"
                width={12}
                height={12}
              />
              Dashboard
            </a>
          )}
          <a className="logo block space-x-2 mx-auto flex items-center" href="/" aria-label="Avatar Maker">
            <img src={logo} alt="Avatar Maker logo" className="logoimg text-center sm:h-[70px] mx-auto" />
            <h1 className='font-bold text-3xl' style={{ fontFamily: "Futura Hv BT Heavy" }}>Avatar Maker</h1>
          </a>
        </header>

        <div className="container py-12 px-4">
          <div className="row">
            <div className="flex flex-wrap">
              <div className="w-full sm:w-1/2 p-2">
                <div id="upload_area"></div>
                <div className="leftdetails pt-4">
                  <div className="positiveprompt">
                    <label htmlFor="positive-prompt" className="form-label flex flex-wrap py-1 mt-10 text-black text-center font-bold mt-4">
                      Positive Prompt:
                    </label>
                    <textarea ref={positivepromptRef} className={`form-control w-full p-4 prompts border mt-0 border-dashed ${allowedToType ? '' : 'opacity-75 disabled cursor-not-allowed pointer-events-none'}`} id="positive-prompt" rows="3" onChange={setGenBtnViaPrompt}  ></textarea>
                  </div>
                  <div className="negativeprompt">
                    <label htmlFor="negative-prompt" className="form-label flex flex-wrap py-1 mt-10 text-black text-center font-bold mt-4">
                      Negative Prompt:
                    </label>
                    <textarea className={`form-control w-full p-4 prompts border mt-0 border-dashed ${allowedToType ? '' : 'opacity-75 disabled cursor-not-allowed pointer-events-none'}`} id="negative-prompt" rows="3"></textarea>
                  </div>
                  <div className="rightdetails">
                    <label className="flex flex-wrap py-1 mt-10 text-black text-center font-bold mt-4">Aspect Ratio:</label>
                    <div className="aspect-ratio-buttons border rounded p-4">
                      <button type="button" className="aspect-ratio-btn active text-black hover:text-white text-[13px] bg-white border border-[#16A34A] hover:bg-[#16A34A] px-4 py-1 mx-1 mb-1 rounded drop-shadow-md" data-width="768" data-height="768">Square (1:1)</button>
                      <button type="button" className="aspect-ratio-btn text-black hover:text-white text-[13px] bg-white border border-[#16A34A] hover:bg-[#16A34A] px-4 py-1 mx-1 mb-1 rounded drop-shadow-md" data-width="640" data-height="896">Portrait (3:4)</button>
                      <button type="button" className="aspect-ratio-btn text-black hover:text-white text-[13px] bg-white border border-[#16A34A] hover:bg-[#16A34A] px-4 py-1 mx-1 mb-1 rounded drop-shadow-md" data-width="896" data-height="640">Landscape (4:3)</button>
                      <button type="button" className="aspect-ratio-btn text-black hover:text-white text-[13px] bg-white border border-[#16A34A] hover:bg-[#16A34A] px-4 py-1 mx-1 mb-1 rounded drop-shadow-md" data-width="576" data-height="1024">Mobile (9:16)</button>
                      <button type="button" className="aspect-ratio-btn text-black hover:text-white text-[13px] bg-white border border-[#16A34A] hover:bg-[#16A34A] px-4 py-1 mx-1 mb-1 rounded drop-shadow-md" data-width="1024" data-height="576">Desktop (16:9)</button>
                    </div>
                  </div>
                  <button
                    type="button"
                    className={`btn w-full px-4 py-4 text-[#ffffff] bg-gradient-to-r from-[#16a34a] to-green-700 hover:from-green-400 hover:to-green-600 rounded mt-8 font-extrabold ${allowedToGenerate ? '' : 'opacity-50 cursor-not-allowed'}`}
                    disabled={!allowedToGenerate}
                    id="generate"
                    onClick={handleGenerateButtonClick}
                  >GENERATE
                  </button>
                </div>
              </div>
              <div className="w-full sm:w-1/2 p-2">
                <div ref={resultEl} className="result-box" id="result-container"></div>
              </div>
            </div>
            <div className="row">
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Avatar;
